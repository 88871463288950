var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "err_page"
  }, [_c("div", {
    staticClass: "content"
  }, [_c("div", [_c("img", {
    staticClass: "first",
    attrs: {
      src: _vm.notFoundImg,
      alt: ""
    }
  })]), _c("div", {
    staticClass: "right"
  }, [_c("img", {
    staticClass: "com_img second",
    attrs: {
      src: _vm.textImgL,
      alt: ""
    }
  }), _c("img", {
    staticClass: "com_img third",
    attrs: {
      src: _vm.textImgS,
      alt: ""
    }
  }), _c("br"), _c("a-button", {
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: _vm.pushHome
    }
  }, [_vm._v("返回首页")])], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };