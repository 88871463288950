import "core-js/modules/es.array.push.js";
export default {
  name: "error",
  data: function data() {
    return {
      notFoundImg: require('../../assets/img/404/not_fond_img.png'),
      textImgL: require('../../assets/img/404/textL.png'),
      textImgS: require('../../assets/img/404/textS.png')
    };
  },
  methods: {
    pushHome: function pushHome() {
      this.$router.push({
        path: "/welcome/index"
      });
    }
  }
};